exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-[slug]-js": () => import("./../../../src/pages/categories/[slug].js" /* webpackChunkName: "component---src-pages-categories-[slug]-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-categories-row-js": () => import("./../../../src/pages/categories/row.js" /* webpackChunkName: "component---src-pages-categories-row-js" */),
  "component---src-pages-database-js": () => import("./../../../src/pages/database.js" /* webpackChunkName: "component---src-pages-database-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-myorders-js": () => import("./../../../src/pages/myorders.js" /* webpackChunkName: "component---src-pages-myorders-js" */),
  "component---src-pages-news-[id]-js": () => import("./../../../src/pages/news/[id].js" /* webpackChunkName: "component---src-pages-news-[id]-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-obv-[id]-js": () => import("./../../../src/pages/obv/[id].js" /* webpackChunkName: "component---src-pages-obv-[id]-js" */),
  "component---src-pages-obv-index-js": () => import("./../../../src/pages/obv/index.js" /* webpackChunkName: "component---src-pages-obv-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-sklad-index-js": () => import("./../../../src/pages/sklad/index.js" /* webpackChunkName: "component---src-pages-sklad-index-js" */)
}

